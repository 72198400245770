<template>
  <div class="service-category-setting">
    <PageTitle title="訂單列表" btn="匯出" @btnClick="showExportOptions = true" />

    <FiltersContainer>
      <!-- 訂單編號 -->
      <el-input
        v-model="search.code"
        clearable
        placeholder="請輸入訂單編號"
        @keypress.enter.native="refresh"
        @clear="refresh"
      >
        <i
          slot="suffix"
          class="el-input__icon el-icon-search"
          @click="refresh"
        />
      </el-input>
      <!-- 會員搜尋 -->
      <MemberSearch
        :model.sync="search.member"
        @change="refresh"
        @clear="refresh"
      />
      <!-- 訂單狀態 -->
      <el-select
        v-model="search.status"
        class="status-select"
        placeholder="請選擇訂單狀態"
        clearable
        @change="refresh"
        @clear="refresh"
      >
        <el-option
          v-for="status in orderStatusConfig"
          :key="status.value"
          :label="status.name"
          :value="status.value"
        />
      </el-select>
      <!-- 運送方式 -->
      <DeliverySelect
        :model.sync="search.delivery"
        objKey="id"
        @change="refresh"
        @clear="refresh"
      />
      <!-- <span class="edit-btn" @click="showDialog = true">編輯</span> -->
    </FiltersContainer>

    <section>
      <el-table
        v-loading="loading"
        :data="productCategoryList"
        empty-text="暫無數據"
      >
        <EmptyBlock slot="empty" />
        <el-table-column label="訂單日期" prop="code" align="center">
          <template slot-scope="scope">
            {{ dateFormat(scope.row.createdAt) }}
          </template>
        </el-table-column>
        <el-table-column
          label="姓名"
          prop="Member.UserInfo.name"
          align="center"
        />
        <el-table-column label="訂單編號" prop="code" align="center" />
        <el-table-column label="狀態" prop="status" align="center">
          <template slot-scope="scope">
            <Tag :type="tagType(scope.row.status)">
              {{ orderStatus(scope.row.status, scope.row.cancelBy) }}
            </Tag>
          </template>
        </el-table-column>
        <el-table-column
          label="訂單運送方式"
          prop="EcommerceOrderDelivery.name"
          align="center"
        >
          <template slot-scope="scope">
            {{
              scope.row.EcommerceOrderDelivery
                ? scope.row.EcommerceOrderDelivery.name
                : ''
            }}
          </template>
        </el-table-column>
        <el-table-column label="訂單總金額" prop="totalPrice" align="center" />
        <el-table-column label="操作" fixed="right" width="110" align="center">
          <template slot-scope="scope">
            <TableEditBtnGroup
              editBtn="檢視"
              hideDelete
              @edit="checkDetail(scope.row.id)"
            />
          </template>
        </el-table-column>
      </el-table>

      <Pagination
        :curPage.sync="tableOptions.page"
        :pageLimit="tableOptions.pageLimit"
        :total="orderCount"
        @pageChange="refresh"
      />
    </section>

    <ExportOptionsDialog
      v-if="showExportOptions"
      @close="showExportOptions = false"
      @export="showExportOptions = false, prepareExport($event)"
    />
    <ExportDialog
      v-if="showExportDialog"
      :inProgress="exportting"
      :isError="exportError"
      :percentage="exportPercentage"
      :data="exportData"
      :total="orderCount"
      @close="resetExport"
    />
  </div>
</template>

<script>
import {
  invoiceStatusConfig,
  orderStatusConfig,
  deliveryTypesConfig,
  cancelByKeysConfig,
  paymentStatusConfig,
  paymentConfig,
  orderDeliveryStatusName,
} from '@/config/ecommerce'
import ExportOptionsDialog from '@/components/Dialog/ExportOptionsDialog.vue'
import ExportDialog from '@/components/Dialog/ExportDialog.vue'
import MemberSearch from '@/components/Search/MemberSearch.vue'
import DeliverySelect from '@/components/Select/ecommerce/DeliverySelect.vue'
import { ExportMoreSheetExcel } from '@/utils/excel'
import { mapGetters } from 'vuex'
import EmptyBlock from '@/components/EmptyBlock.vue'
import { checkUserFeature } from '@/store/modules/permission'
import { GetOrder, GetOrderCount, ExportOrder } from '@/api/ecommerce/order'
import dayjs from 'dayjs'
import { get, filter, map } from 'lodash'
// Utils
import { pageStartIndex } from '@/utils/table'
import formUtils from '@/utils/form'
import { extractList } from '@/utils/helper'

// import * as cssvars from '@/styles/ohbot/_variables.scss'

export default {
  name: 'ServiceCategorySetting',
  components: { EmptyBlock, MemberSearch, DeliverySelect, ExportOptionsDialog, ExportDialog },

  data: () => ({
    deliveryTypesConfig,
    invoiceStatusConfig,
    paymentStatusConfig,
    orderDeliveryStatusName,
    showExportOptions: false,
    showExportDialog: false,
    exportting: false,
    exportError: false,
    exportData: [],
    exportPercentage: 0,

    loading: false,

    search: {
      code: '',
      delivery: '',
      member: '',
      status: '',
    },

    showDialog: false,
    deleteDialog: false,
    dialogType: '',
    cancelByKeysConfig,
    paymentConfig,
    orderStatusConfig,
    productCategoryList: [],
    orderCount: 0,

    selectRow: null,
    tableOptions: {
      page: 1,
      pageLimit: 10,
    },

    avatarChanged: false,
    imgSize: 90,

    formData: {
      name: '',
      id: '',
    },
  }),
  computed: {
    ...mapGetters(['shop', 'userPlanFeature', 'userFeatures']),
    pageStartIndex () {
      return pageStartIndex(this.tableOptions.page, this.tableOptions.pageLimit)
    },
    imageIdList () {
      return extractList('id', this.formData.Images)
    },
  },

  async mounted () {
    await this.refresh()
  },

  methods: {
    async getExportOrder (e) {
      this.loading = true
      const allRange = e.all
      const haveRange = e.range
      const limit = 100
      let start = 0

      while (this.exportData.length < this.orderCount) {
        const [res, err] = await ExportOrder({
          shopId: this.shop,
          start,
          limit,
          createdAtStart: (!allRange && haveRange) ? dayjs(e.range[0]).format('YYYY-MM-DD') : undefined,
          createdAtEnd: (!allRange && haveRange) ? dayjs(e.range[1]).format('YYYY-MM-DD') : undefined,
        })
        if (err) {
          this.loading = false
          this.$message.error(err)
          return
        }
        start += limit
        this.exportData.push(...res)
        this.exportPercentage = Math.round((this.exportData.length / this.orderCount) * 100)
      }
      this.loading = false
    },
    resetExport () {
      this.showExportDialog = false
      this.exportting = false
      this.exportError = false
      this.exportData = []
      this.exportPercentage = 0
    },
    async prepareExport (e) {
      const permission = checkUserFeature(this.userPlanFeature, this.userFeatures, 'admin.member.exportExcel')
      if (!permission) return this.$message.error('無此權限 admin.member.exportExcel')
      this.exportError = false
      this.showExportDialog = true
      this.exportting = true
      await this.getOrderCount(e)
      if (!this.orderCount) {
        this.$message.warning('沒有資料可以匯出')
        return this.resetExport()
      }
      await this.getExportOrder(e)
      await this.formatExportData(e.range)
      this.exportting = false
    },

    async formatExportData (range) {
      const detailData = []
      const data = []
      this.exportData.forEach((item, i) => {
        const findPromoCode = () => {
          let promoCode
          const code = []
          if (item.EcommerceOrderPromoByOrders) {
            const res = filter(item.EcommerceOrderPromoByOrders, i => i.EcommercePromoCodeId)
            code.push(...res)
          }
          if (item.EcommerceOrderPromoByProducts) {
            const res = filter(item.EcommerceOrderPromoByProducts, i => i.EcommercePromoCodeId)
            code.push(...res)
          }
          if (code.length) {
            promoCode = code[0]
            return `${promoCode.promoContentName} -${promoCode.saveTotal}`
          }
          return null
        }

        item.EcommerceOrderProducts.forEach((product, index) => {
          const defaultOrNot = (val, config) => {
            // if (index) return ''
            // if (!val && !index) return '-'
            if (!val) return '-'
            if (!config) return val
            return get(config, `${val}.name`, null) || get(config, val, null) || val
          }
          const detailRow = {
            訂單日期: defaultOrNot(this.dateFormat(item.createdAt)),
            訂單編號: defaultOrNot(item.code),
            訂單金額: defaultOrNot(item.totalPrice),
            訂單狀態: defaultOrNot(this.orderStatus(item.status, item.cancelBy)),
            會員姓名: defaultOrNot(get(item, 'Member.UserInfo.name')),
            付款方式: defaultOrNot(get(item, 'EcommercePaymentRecord.paymentType'), this.paymentConfig),
            付款編號: defaultOrNot(get(item, 'EcommercePaymentRecord.code')),
            付款狀態: defaultOrNot(get(item, 'EcommercePaymentRecord.status'), this.paymentStatusConfig),
            儲值金狀態: defaultOrNot(item.walletPrice ? '使用' : '未使用'),
            發票狀態: defaultOrNot(get(item, 'EcommerceOrderInvoice.status'), this.invoiceStatusConfig),
            發票號碼: defaultOrNot(get(item, 'EcommerceOrderInvoice.invoiceNo')),
            發票種類: defaultOrNot(get(item, 'EcommerceOrderInvoice.type')),
            公司抬頭: defaultOrNot(get(item, 'EcommerceOrderInvoice.customerName')),
            統一編號: defaultOrNot(get(item, 'EcommerceOrderInvoice.customerIdentifier')),
            運送方式: defaultOrNot(get(item, 'EcommerceOrderDelivery.name')),
            運送編號: defaultOrNot(get(item, 'EcommerceOrderDelivery.code')),
            運送狀態: defaultOrNot(get(item, 'EcommerceOrderDelivery.status'), this.orderDeliveryStatusName),
            收件人姓名: defaultOrNot(get(item, 'EcommerceOrderDelivery.receiver')),
            收件人電話: defaultOrNot(get(item, 'EcommerceOrderDelivery.phone')),
            收件人住址: defaultOrNot(get(item, 'EcommerceOrderDelivery.address')),
            商品名稱: product.name || '-',
            商品規格: product.specName || '-',
            原價格: product.productPrice || '-',
            優惠價: product.price || '-',
            數量: product.quantity || '-',
            商品規格備註: product.adminNote || '-',
            活動金額: product.totalPrice || '-',
            運費: defaultOrNot(item.totalDeliveryPrice),
            折扣碼: defaultOrNot(findPromoCode()),
            '結帳優惠（全館活動）': defaultOrNot(get(item, 'totalProductPromoPrice')),
            儲值金: defaultOrNot(item.walletPrice),
            結帳金額: defaultOrNot(item.paymentPrice),
            消費者備註: defaultOrNot(get(item, 'EcommerceOrderNoteMember.text')),
            告知消費者備註: defaultOrNot(get(item, 'EcommerceOrderNoteShop.text')),
            內部備註: defaultOrNot(get(item, 'EcommerceOrderNoteAdmin.text')),
          }
          const row = {
            訂單日期: defaultOrNot(this.dateFormat(item.createdAt)),
            訂單編號: defaultOrNot(item.code),
            訂單狀態: defaultOrNot(this.orderStatus(item.status, item.cancelBy)),
            收件人姓名: defaultOrNot(get(item, 'EcommerceOrderDelivery.receiver')),
            收件人電話: defaultOrNot(get(item, 'EcommerceOrderDelivery.phone')),
            收件人住址: defaultOrNot(get(item, 'EcommerceOrderDelivery.address')),
            運送方式: defaultOrNot(get(item, 'EcommerceOrderDelivery.name')),
            運送狀態: defaultOrNot(get(item, 'EcommerceOrderDelivery.status'), this.orderDeliveryStatusName),
            運送編號: defaultOrNot(get(item, 'EcommerceOrderDelivery.code')),
            有購買的商品: `${product.name} / ${product.specName} / ${product.quantity}`,
            商品規格備註: product.adminNote || '-',
            消費者備註: defaultOrNot(get(item, 'EcommerceOrderNoteMember.text')),
          }

          detailData.push(detailRow)
          data.push(row)
        })
      })

      const sheetList = [
        {
          sheetName: '訂單詳細資料',
          data: detailData,
        },
        {
          sheetName: '出貨用資料',
          data,
        },
      ]
      let fileName = '訂單匯出清單'
      if (range) fileName = `訂單匯出清單_${dayjs(range[0]).format('YYYY-MM-DD')} ~ ${dayjs(range[1]).format('YYYY-MM-DD')}`
      ExportMoreSheetExcel(sheetList, fileName)
    },

    dateFormat (date) {
      return dayjs(date).format('YYYY-MM-DD HH:mm')
    },
    tagType (val) {
      let type = 'warning'
      if (val === 'completed') type = 'success'
      if (val === 'open') type = 'action'
      if (val === 'picking') type = 'warning'
      if (val === 'shipped') type = 'success'
      if (val === 'requestRefunded') type = 'danger'
      if (val === 'cancel') type = 'info'
      return type
    },

    checkDetail (id) {
      this.$router.push(`/order-detail/${id}`)
    },

    orderStatus (status, cancelBy) {
      let label = ''
      if (cancelBy) {
        if (status === 'cancel') label = this.cancelByKeysConfig[cancelBy].name
        else label = this.orderStatusConfig[status].name
      } else label = this.orderStatusConfig[status].name

      return label
    },

    deliveryTypes (status) {
      return this.deliveryTypesConfig[status]
    },

    async refresh () {
      this.loading = true
      await this.getOrder()
      await this.getOrderCount()
      this.loading = false
    },

    //= > 檢查表單輸入驗證
    async checkForm () {
      return await formUtils.checkForm(this.$refs.form)
    },

    //= > 重置表單
    resetForm () {
      formUtils.resetForm(this.$refs.form)
      this.formData = {
        img: null,
        isPublic: true,
        avatar: null,
        name: '',
        order: 100,
        id: '',
        services: [],
      }
    },

    //= > 取得訂單
    async getOrder (all = false) {
      this.loading = true
      if (!all) {
        const startIndex = this.pageStartIndex
        const limit = this.tableOptions.pageLimit
        const [res, error] = await GetOrder({
          shopId: this.shop,
          start: startIndex,
          limit,
          code: !this.search.code ? undefined : this.search.code,
          status: !this.search.status ? undefined : this.search.status,
          MemberId: !this.search.member ? undefined : this.search.member.id,
          EcommerceDeliveryId: !this.search.delivery
            ? undefined
            : this.search.delivery,
        })
        this.loading = false
        if (error) {
          this.loading = false
          return this.$message.error(error)
        }
        this.productCategoryList = res
      }
      if (all) {
        let startIndex = 0
        const limit = 100
        while (this.exportData.length < this.orderCount) {
          const [res, error] = await GetOrder({
            shopId: this.shop,
            start: startIndex,
            limit,
            code: !this.search.code ? undefined : this.search.code,
            status: !this.search.status ? undefined : this.search.status,
            MemberId: !this.search.member ? undefined : this.search.member.id,
            EcommerceDeliveryId: !this.search.delivery
              ? undefined
              : this.search.delivery,
          })
          if (error) {
            this.loading = false
            return this.$message.error(error)
          }
          startIndex += limit
          this.exportData.push(...res)
          this.exportPercentage = Math.round((this.exportData.length / this.orderCount) * 100)
        }
        this.loading = false
      }
    },

    //= > 取得訂單總數
    async getOrderCount (option = {}) {
      this.loading = true
      const [res, error] = await GetOrderCount({
        shopId: this.shop,
        code: !this.search.code ? undefined : this.search.code,
        status: !this.search.status ? undefined : this.search.status,
        MemberId: !this.search.member ? undefined : this.search.member.id,
        EcommerceDeliveryId: !this.search.delivery
          ? undefined
          : this.search.delivery,
        createdAtStart: option.range ? dayjs(option.range[0]).format('YYYY-MM-DD') : undefined,
        createdAtEnd: option.range ? dayjs(option.range[1]).format('YYYY-MM-DD') : undefined,
      })
      this.loading = false
      if (error) return this.$message.error(error)
      this.orderCount = res.count
    },

    async openDialog (type) {
      this.dialogType = type
      this.showDialog = true
    },
  },
}
</script>

<style scoped lang="scss">
.unpaid,
.picking,
.shipped {
  color: #5e5e5e !important;
  background: #d5f4f6 !important;
}

.completed {
  color: white !important;
  background: #2bb8c1 !important;
}

.open {
  color: #5e5e5e !important;
  background: #dcd4f0 !important;
}

.cancel,
.clientCancel,
.adminCancel,
.systemCancel {
  color: #5e5e5e !important;
  background: #dedede !important;
}

.requestRefunded {
  background: #f9e2eb;
  color: #fc6670;
}

.filter-block-container {
  @apply flex;
}

@media screen and (max-width:1024px) {
  .filter-block-container {
    @apply grid gap-[12px];
  }
}
</style>
